import React, {useEffect, useState, Fragment, useContext} from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {addAlert, openDashboard, openLook} from "../../store/actions/appActions";
import {
  DashboardIcon,
  DataTable, EditFolderIcon, LookIcon,
  TextWithCaption,
  SimpleConfirmModal,
  ViewBurstScheduleModal,
  ReassignOwnerModal,
} from "@src/components";
import {
  Button,
  Container,
  GridRow, Icon, IconButton,
  IMenu,
  MoreActions,
  MoreActionsProps,
  Popover, Skeleton,
  Tooltip
} from "@emburse/embark-core";
import { IDataTableColumn } from "@src/interfaces/IDataTableColumn";
import {FFlags, getFlag, IFFlags, Severity} from "@src/enums";
import {flagsSelector} from "@src/store/selector";
import {
  Typography,
  Spacer
} from "@emburse/embark-core";
import { createUseStyles } from "react-jss";

import {DELETE_SCHEDULE, VIEW_SCHEDULE, REASSIGN_OWNER} from "../constants";
import {IReportScheduleDefinition, IDashboardRef, IUserRef, ILookRef} from "../../interfaces";
import {IAlert } from "@src/interfaces";
import {
  IDashboardsDict,
  ILooksDict,
  IScheduleAPIContext,
  IUsersDict,
  ScheduleAPIContext
} from "@components/ScheduleAPIProvider/ScheduleAPIProvider";
import cronstrue from "cronstrue";
import {
  DeleteIcon,
  InfoIcon,
  KeyboardArrowRightIcon,
  PeopleIcon,
} from "@emburse/embark-icons";
import {uniqueId} from "lodash";

const useStyles = createUseStyles({
  main: {
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft[data-field=destinations]": {
      alignItems: 'left !important'
    },

    "& .distributionAddress:nth-child(3)": {
      display: 'inline'
    }
  }
});


export interface AdminBurstSchedulesGridProps {
  onRowClick: ( schedule: IReportScheduleDefinition ) => void;
  schedule: IReportScheduleDefinition | null;
}
const BurstSchedulesGrid = ({onRowClick, schedule} : AdminBurstSchedulesGridProps) => {
  const dispatch = useDispatch();
  const flags = useSelector(flagsSelector);
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const classes = useStyles();

  const [selectedScheduleToView, setSelectedScheduleToView] = useState<IReportScheduleDefinition|null>(null);

  // list of schedules and cross-references
  const [schedules, setSchedules] = useState<IReportScheduleDefinition[]>([]);
  const [referencedUsers, setReferencedUsers ] =  useState<IUsersDict>({});

  const [referencedDashboards, setReferencedDashboards ] =  useState<IDashboardsDict>({});
  const [referencedLooks, setReferencedLooks] = useState<ILooksDict>({});

  // a table state for loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isLoadingLooks, setIsLoadingLooks] = useState<boolean>(false);
  const [isLoadingDashboards, setIsLoadingDashboards] = useState<boolean>(false);

  //modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const closeModalCb = () => {
    setIsModalOpen(false);
    setModalType("");
  };

  function fetchSchedules() {
    scheduleAPI.getSchedules({'type': 'BURST'}).then((res) => {
      setSchedules(res);
      setIsLoading(false);
    }).catch(e => {
      let alert: IAlert = {
        timestamp: new Date().getTime(),
        severity: Severity.ERROR,
        message: "An error occurred when fetching the burst schedules."
      };
      dispatch(addAlert(alert));
    });
  }

  function fetchReferencedUsers(schedules: IReportScheduleDefinition[]) {

    const uniqueUserIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      if ((curr.user_id !== undefined) && (curr.user_id !== null)) {
        acc.add(curr.user_id);
      }
      return acc;
    }, new Set<string>());

    setIsLoadingUsers(true);
    scheduleAPI.getReferencedUsers(Array.from(uniqueUserIds)).then((res) => {
      setIsLoadingUsers(false);
      setReferencedUsers(res);
    }).catch(e => {
      setIsLoadingUsers(false);
    });
  }

  function fetchReferencedLooks(schedules: IReportScheduleDefinition[]) {

    const uniqueLookIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      if ((curr.look_id !== undefined) && (curr.look_id !== null)) {
        acc.add(curr.look_id);
      }
      return acc;
    }, new Set<string>());

    // merge set with distribution looks
    const allLookIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      const firstDestination = curr.destinations[0];
      if ((firstDestination?.distribution_look_id !== undefined) && (firstDestination?.distribution_look_id !== null)) {
        acc.add(firstDestination?.distribution_look_id);
      }
      return acc;
    }, uniqueLookIds);

    setIsLoadingLooks(true);
    scheduleAPI.getReferencedLooks(Array.from(allLookIds)).then((res) => {
      setIsLoadingLooks(false);
      setReferencedLooks(res);
    }).catch(e => {
      setIsLoadingLooks(false);
    });
  }

  function fetchReferencedDashboards(schedules: IReportScheduleDefinition[]) {

    const uniqueDashboardIds: Set<string> = schedules.reduce((acc: Set<string>, curr: IReportScheduleDefinition) => {
      if ((curr.dashboard_id !== undefined) && (curr.dashboard_id !== null)) {
        acc.add(curr.dashboard_id);
      }
      return acc;
    }, new Set<string>());

    setIsLoadingDashboards(true);
    scheduleAPI.getReferencedDashboards(Array.from(uniqueDashboardIds)).then((res) => {
      setIsLoadingDashboards(false);
      setReferencedDashboards(res);
    }).catch(e => {
      setIsLoadingDashboards(false);
    });
  }

  useEffect(() => {
    fetchReferencedUsers(schedules);
    fetchReferencedLooks(schedules);
    fetchReferencedDashboards(schedules);
  }, [schedules]);

  useEffect(() => {
    setIsLoading(true);
    fetchSchedules();
  }, []);

  function handleRowClick({ row }: { row: IReportScheduleDefinition }) {
    onRowClick(row);
    flags?.[getFlag(FFlags.ViewScheduleOnScheduleAdminNameClick)] && showScheduleDetailsModal();
  }

  const doOpenReferencedItem = (is_dashboard: boolean, ref_id: string) => {
    if (is_dashboard) {
      dispatch(openDashboard(ref_id));
    } else {
      dispatch(openLook(ref_id));
    }
  };

  const scheduleColumns: IDataTableColumn[] = [
    {
      field: "name",
      headerName: "Burst Schedule Name",
      flex: 4,
      renderCell: (params) => {
        return (
          <div onClick={() => handleRowClick(params)}>
            <TextWithCaption
                text={params.row.name}
                caption={cronstrue.toString(params.row.schedule, { verbose: true }) + ", " + params.row.timezone}
            />
          </div>
        );
      },
    },
    {
      field: "dashboard_look_id",
      headerName: "Dashboard/Look",
      flex: 2,
      sortable: false,
      renderCell: (params) => {

        if (!params.row.is_dashboard && isLoadingLooks) {
          return (
              <Skeleton><Typography variant={"body1"}>{params.row.dashboard_look_id}</Typography></Skeleton>
          );
        }

        if (params.row.is_dashboard && isLoadingDashboards) {
          return (
              <Skeleton><Typography variant={"body1"}>{params.row.dashboard_look_id}</Typography></Skeleton>
          );
        }

        const referencedReport = (params.row.is_dashboard ? referencedDashboards[params.row.dashboard_look_id] : referencedLooks[params.row.dashboard_look_id]);
        let referenceName = '';
        if (referencedReport) {
          referenceName = referencedReport?.name;
        } else {
          referenceName = params.row.dashboard_look_id;
        }

        return (
          <>
            <Icon iconUrl={params.row.is_dashboard ? <DashboardIcon /> : <LookIcon />}/>
            <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => doOpenReferencedItem(params.row.is_dashboard, params.row.dashboard_look_id)}>{referenceName}</span>
          </>
        );
      },
    },
    {
      field: "distribution_list_id",
      headerName: "Distribution Look",
      flex: 2,
      sortable: false,
      renderCell: (params) => {

        if (isLoadingLooks) {
          return (
              <Skeleton><Typography variant={"body1"}>{params.row.destinations[0]?.distribution_look_id}</Typography></Skeleton>
          );
        }

        const referencedDistributionLook = referencedLooks[params.row.destinations[0]?.distribution_look_id];
        let distributionLookName = '';
        if (referencedDistributionLook) {
          distributionLookName = referencedDistributionLook?.name;
        } else {
           distributionLookName = params.row.destinations[0].distribution_look_id;
        }

        return (
            <>
              <span style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => doOpenReferencedItem(false, params.row.destinations[0].distribution_look_id)}> {distributionLookName}</span>
            </>
        );
      },
    },
    {
      field: "user_id",
      headerName: "Owner",
      flex: 2,
      sortable: false,
      renderCell: (params) => {

        if (isLoadingUsers) {
          return (
              <Skeleton><Typography variant={"body1"}>{params.row.user_id}</Typography></Skeleton>
          );
        }

        const referencedUser = referencedUsers[params.row.user_id];

        if (referencedUser) {
          return (<Tooltip title={params.row.user_id}><span style={{cursor: 'default', overflow: 'hidden', textOverflow: 'ellipsis'}}>{referencedUsers[params.row.user_id]?.first_name} {referencedUsers[params.row.user_id]?.last_name}</span></Tooltip>);
        } else {
            return (<span>{params.row.user_id}</span>);
        }
      }
    }
  ];
  
  flags?.[getFlag(FFlags.ScheduleAdminGridMoreActions)] ? scheduleColumns.push({
    field: "openModal",
    headerName: "",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      const moreActionsMenu: IMenu = {
        sections: []
      };
    
      const actionItems = moreActionsMenu.sections;
      //@ts-ignore
      flags?.[getFlag(FFlags.ScheduleAdminDeleteSchedule)] && actionItems.push({
        items: [
          {
            iconText: 'Delete Schedule',
            closeOnClick: true,
            icon: DeleteIcon,
            onClick: () => {
              setIsModalOpen(true);
              setModalType(DELETE_SCHEDULE);
            }
          }
        ]
      });
     
      flags?.[getFlag(FFlags.BurstScheduleAdminReassignOwner)] && actionItems?.push({
        items: [
          {
            iconText: 'Reassign Owner',
            closeOnClick: true,
            icon: PeopleIcon,
            onClick: () => {
              setIsModalOpen(true);
              setModalType(REASSIGN_OWNER);
            }
          }
        ]
      });

      return (
        <MoreActions data-tag="schedulesGridMoreActions" className="da-more-actions" moreActionsMenu={moreActionsMenu}/>
      );
    },
  }) : scheduleColumns.push({
    field: "open",
    headerName: "",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      return (
          <>
            <Icon iconUrl={KeyboardArrowRightIcon} />
          </>
      );
    },
  });  

  const showScheduleDetailsModal = () => {
    setIsModalOpen(true);
    setModalType(VIEW_SCHEDULE);
  };

  const showModalForType = () => {
    switch(modalType) {
      case DELETE_SCHEDULE:
        return <SimpleConfirmModal
          open={modalType === DELETE_SCHEDULE}
          showClose={true}
          headerTitle="Delete Schedule"
          confirmText="Delete"
          cancelDataTag="cancelDeleteBurstSchedule"
          confirmDeleteDataTag="confirmDeleteBurstSchedule"
          onCloseClicked={()=> {
            setModalType('');
            setIsModalOpen(false);
          }}
          onConfirm={async () => {
            if (schedule) {
              await scheduleAPI.deleteSchedule(schedule);
              setModalType('');
              setIsModalOpen(false);
              setIsLoading(true);
              fetchSchedules();
            }
            return false;
          }}
        >
          <Typography variant="subtitle1">
            {schedule?.name}
          </Typography>
          <Typography>
            Are you sure you want to delete this selected schedule?
          </Typography>
        </SimpleConfirmModal>;
      case VIEW_SCHEDULE:
        return <ViewBurstScheduleModal schedule={schedule} isModalOpen={isModalOpen} closeModalCb={closeModalCb}/>;
      case REASSIGN_OWNER:
        return <ReassignOwnerModal
        open={modalType === REASSIGN_OWNER}
        schedule={schedule}
        onCloseClicked={()=> {
          setModalType('');
          setIsModalOpen(false);
        }}
        onConfirm={async (user) => {
          const scheduleId = schedule?.id;
          if (scheduleId) {
            await scheduleAPI.burstReassignOwner(scheduleId, user.user_id);
            setModalType('');
            setIsModalOpen(false);
            setIsLoading(true);
            await fetchSchedules();
            return true;
          }
          return false;
        }}
      />;
    }
  };

  let filterSchedule = function(schedule){
    /**
     * Apply client-side filtering
     * */
    return true;
  };

  let handleScheduleFilter = (): any[] => {
    /**
     * Provides the list of schedules after filtering
     */
    return schedules
        ?.map((schedule: IReportScheduleDefinition) => {
          return {
            name: `${schedule.name}`,
            id: schedule.id,
            dashboard_look_id: schedule.dashboard_id ? schedule.dashboard_id : schedule.look_id,
            is_dashboard: !!schedule.dashboard_id,
            user_id: schedule.user_id,
            user: schedule.user,
            type: schedule.type,
            schedule: schedule.crontab,
            timezone: schedule.timezone,
            destinations: schedule.destinations,
            raw: schedule,
          };
        })
        .filter((el) => {
          // client side filtering
          return filterSchedule(el);
        })
        // alphabetically sort by name field
        .sort((a,b) => a.name.localeCompare(b.name));
  };


  return (
    <>
      <Fragment>
        <GridRow justifyContent={'space-between'}>
          <Typography variant="h4">Burst Schedules</Typography>
        </GridRow>
        <Spacer size={16} direction="vertical" />
      </Fragment>

      <DataTable
        autoHeight
        allowSelection={false}
        loading={isLoading}
        rowHeight={68}
        rows={handleScheduleFilter()}
        columns={scheduleColumns}
        onRowClick={flags?.[getFlag(FFlags.ViewScheduleOnScheduleAdminNameClick)] ? () => {} : handleRowClick}
      />
      {flags?.[getFlag(FFlags.ScheduleAdminShowOwnerNameDashLookName)] && modalType ? showModalForType() : null}
    </>
  );
};

export default BurstSchedulesGrid;
