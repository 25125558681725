import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { IHasChildrenProps } from '../../interfaces';
import { push } from 'connected-react-router';
import { UNAUTHORIZED_ROUTE } from '../../store/routs';
import {useEffect} from 'react';
import { IState } from '@src/interfaces';

export interface PageWrapperProps extends IHasChildrenProps{

}
export default (props: PageWrapperProps) => {
    const dispatch = useDispatch()

    const {userData} = useSelector((state: IState) => state.user)

    useEffect(() => {
        if(!userData.authorized){
            dispatch(push(UNAUTHORIZED_ROUTE))
        }
    }, [dispatch, userData.authorized])

    return(
        <>
            {props.children}
        </>
    )
}