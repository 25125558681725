import React, { SetStateAction, useContext, useState } from "react";
import { Chip, Grid, Icon, Link } from "@emburse/embark-core";
import { Typography } from "@emburse/embark-core";
import { Edit, DeleteFolderIcon } from "../Icons/Icons";
import { createUseStyles } from "react-jss";
import Colors from "@src/enums/Colors";
import {Styles, Timezones} from "@src/enums";
import { AnalyticsRole } from "../../enums/AnalyticsRole";
import {
  IUseModal,
  IReportScheduleDefinition,
  IDashboard,
  isEmailDistribution,
  isSFTPDistribution
} from "@src/interfaces";
import {
  IScheduleAPIContext,
  ScheduleAPIContext,
} from "../ScheduleAPIProvider/ScheduleAPIProvider";
import cronstrue from "cronstrue";
import Scheduling from "@src/classes/Scheduling";

import { SimpleConfirmModal } from "@src/components";
import { ILook } from "@interfaces";
import { flagsSelector } from "../../store/selector";
import { useSelector } from "react-redux";
import { FFlags, getFlag } from "../../enums/FFlags";
import moment from "moment-timezone";
import {IUseAuthInterface} from "@hooks/useAuth";
import {AuthContext} from "@components/AuthProvider/AuthProvider";
import {isInternalUser} from "@components/Schedule/ScheduleModal";
import { SCHEDULE_BURST_FORM_VIEW, SCHEDULE_FORM_VIEW } from "./constants";

export interface ScheduleListItemProps {
  permission: AnalyticsRole | undefined;
  schedule: IReportScheduleDefinition;
  type: "look" | "dashboard";
  item: IDashboard | ILook;
  folderId: any;
  setSchedule: React.Dispatch<SetStateAction<any>>;
  setModalState?: React.Dispatch<SetStateAction<any>> | undefined;
}

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "24px",
    paddingBottom: "24px",
    borderBottom: "1px solid #E0E0E0",
    width: "100%",
  },
  actions: {
    display: "flex",
    width: "100px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  action: {
    "& > svg": {
      width: "18px",
      color: Colors.Button.Grey,
    },
  },
  chips: {
    marginTop: Styles.Gutters.Margin(2),
    "& > div": {
      marginRight: Styles.Gutters.Padding(1),
    },
  },
});

export default (props: ScheduleListItemProps) => {
  let sch = Scheduling(props.schedule);
  const { user } = useContext<IUseAuthInterface>(AuthContext);

  const classes = useStyles();
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const flags = useSelector(flagsSelector);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // console.log(Scheduling.findIncrements(0, 59, 5))

  const handleEditSchedule = () => {

    if (flags?.[getFlag(FFlags.BurstEnabledForInternalUsersOnly)]) {
      if (!isInternalUser(user) && sch.schedule?.type === 'BURST') {
        // ignore request to edit if not internal user and it's a burst
        return;
      }
    }

    props.setSchedule(sch);
    const modalState = sch.schedule?.type === "BURST" ? SCHEDULE_BURST_FORM_VIEW : SCHEDULE_FORM_VIEW;
    props.setModalState && props.setModalState(modalState);
  };

  return (
    <Grid container alignItems="center">
      <Grid item sm={9} md={9} lg={9} xl={9}>
        <Typography variant="body1">{props.schedule.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {props.schedule.crontab &&
            cronstrue.toString(props.schedule.crontab, { verbose: true })}
          {props.schedule.timezone && (
              ` (${moment().tz(props.schedule.timezone).format('z')})`
          )}
        </Typography>
        <div className={classes.chips}>
          {sch.schedule?.type === "BURST" && (
            <Chip label={"Burst"} color="primary" variant="outlined" />
          )}
          {sch.type && (
            <Chip label={sch.type} color="primary" variant="outlined" />
          )}
          {isEmailDistribution(sch.schedule) && (
              <Chip label={"Email"} color="primary" variant="outlined" />
          )}
          {isSFTPDistribution(sch.schedule) && (
              <Chip label={"SFTP"} color="primary" variant="outlined" />
          )}
        </div>
      </Grid>
      {props.permission &&
        [AnalyticsRole.ProCreator].includes(
          props.permission
        ) && (
          <Grid
            sm={3}
            md={3}
            lg={3}
            xl={3}
            container
            justifyContent="flex-end"
            className={classes.actions}
            spacing={2}
          >
            <Grid item>
              <Link className={classes.action} onClick={handleEditSchedule}>
                <Icon iconUrl={<Edit />} />
              </Link>
            </Grid>
            {/* <Link className={classes.action} onClick={() => {scheduleAPI.copySchedule(props.schedule)}}><Icon iconUrl={<FileCopyIcon />} /></Link> */}
            {flags?.[getFlag(FFlags.EnableDeleteSchedule)] && (
              <Grid item>
                <Link
                  className={classes.action}
                  onClick={() => setShowDeleteModal(true)}
                >
                  <Icon iconUrl={<DeleteFolderIcon />} />
                </Link>
                <SimpleConfirmModal
                  open={showDeleteModal}
                  showClose={true}
                  headerTitle="Delete Schedule"
                  confirmText="Delete"
                  onCloseClicked={() => setShowDeleteModal(false)}
                  onConfirm={async () => {
                    if (sch.schedule) {
                      return scheduleAPI.deleteSchedule(sch.schedule);
                    }
                    return false;
                  }}
                >
                  <Typography variant="subtitle1">
                    {props.schedule?.name}
                  </Typography>
                  <Typography>
                    Are you sure you want to delete this selected schedule?
                  </Typography>
                </SimpleConfirmModal>
              </Grid>
            )}
          </Grid>
        )}
    </Grid>
  );
};
