import { SessionStatus } from "../../enums";
import { AnalyticsProStatus } from "../../enums/AnalyticsProStatus";
import { IUser } from "../../interfaces"
import { FETCH_USER_DATA, SET_USER_DATA, SET_USER_FEATURE_FLAGS, SET_USER_SESSION_ID, SET_USER_SESSION_STATUS } from "../types";
export interface IUserState{
    userData: IUser,
    session_id?: string,
    session_state?: SessionStatus
}

let userData: IUser = JSON.parse(localStorage.getItem("user") || '{}')

// ALLOW LOGIN BEFORE PROVISIONING
let session_status: string = userData.authorized === false ? SessionStatus.UNAUTHORIZED : (localStorage.getItem("session_status") || '')
let session_id: string = localStorage.getItem("session_id") || ''

userData.analytics_pro_status = AnalyticsProStatus.IsNotPro

const initialState: IUserState = {
    userData: userData,
    session_id: session_id,
    session_state: SessionStatus[session_status]
}

export const userReducer = (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
        case FETCH_USER_DATA:
            return {...state, userData: action.payload}
        case SET_USER_DATA: {
            return {...state, userData: {...state.userData, ...action.payload}}
        }
        case SET_USER_SESSION_STATUS:
            return {...state, session_state: action.payload}
        case SET_USER_SESSION_ID:
            return {...state, session_id: action.payload}
        case SET_USER_FEATURE_FLAGS:
            return {...state, userData: {...state.userData, flags: action.payload}}
        default:
            return state;
    }
}