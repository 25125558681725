import React, { useEffect } from 'react'
import Cactus from '../Icons/Cactus'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { logoutUser, setUserData, userNotAuthed } from '@store/actions/userActions';
import { IUser } from '../../interfaces';
import EmptyPage from '../EmptyPage/EmptyPage';
import { LOGOUT_ROUTE } from '@store/routs';

export default () => {
    const dispatch = useDispatch()
    const user: IUser = useSelector((state: RootStateOrAny) => state.user.userData)

    
    useEffect(() => {
        if(user.authorized){
            dispatch(setUserData(userNotAuthed))
            dispatch(logoutUser())
        }
        else if(user.authorized === false && user.initRoute === LOGOUT_ROUTE){
            localStorage.setItem("user", JSON.stringify(userNotAuthed))
            dispatch(setUserData(userNotAuthed))
        }
    }, [dispatch, user])
        

    return (
        <EmptyPage>
            <Cactus />
            <div className="da-empty-page-text-block">
                <h6 className="da-empty-page-text-block-heading">Logged Out</h6>
                <p>Please login again to use this application.</p>   
            </div>
        </EmptyPage>
    )
}